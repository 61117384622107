import React from "react";

import "../css/Footer.css";
import Logo from "../visuals/logomiddle.png";

import X from "../visuals/mdi_x.png";
import Linkedin from "../visuals/mdi_linkedin.png";
import Instagram from "../visuals/mdi_instagram.png";

const Footer = ({ openContact }) => {
  let date = new Date();
  let currentYear = date.getFullYear();

  return (
    <div className="footer flex flexRow">
      <div className="footer-first flex flexColumn">
        <div className="footer-logo">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-first-inner flex flexColumn">
          <p className="footer-copyright">
            © 2024 - {currentYear} ECG MEDIA - All Rights Reserved.
          </p>
          <div className="legal flex flexRow">
            <a className="legal-link" href="/privacy">
              Privacy Policy
            </a>
            <a className="legal-link" href="/tos">
              Terms of Service
            </a>
          </div>
          <div className="facebook">
            This site is not a part of the Facebook website or Facebook Inc.
            Additionally, This site is NOT endorsed by Facebook in any way.
            FACEBOOK is a trademark of FACEBOOK, Inc.
          </div>
        </div>
      </div>
      <div className="footer-right flex flexColumn">
        <p className="footer-long-text">
          Pioneering social media advertising with innovative strategies to
          elevate your brand.{" "}
          <span className="footer-connect">
            <a className="no-decoration" onClick={openContact}>
              Connect with us
            </a>
          </span>{" "}
          to amplify your digital presence.
        </p>
        <div className="footer-socials flex flexColumn">
          <div className="footer-socials-container flex flexRow">
            <a
              href="https://www.linkedin.com/company/ecgmedia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="footer-socials-icon" src={Linkedin} alt="" />
            </a>
            <a
              href="https://www.instagram.com/ecgmediaorg/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="footer-socials-icon" src={Instagram} alt="" />
            </a>
            <a href="https://x.com/MediaECG" target="_blank">
              <img className="footer-socials-icon" src={X} alt="" />
            </a>
          </div>
          <div className="footer-email">contact@ecgmedia.org</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
