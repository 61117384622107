import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView, InView } from "react-intersection-observer";
import CountUp from "react-countup";

import "../css/Stats.css";

import wave from "../visuals/wave2.png";
import wave2 from "../visuals/wave3.png";

const Stats = () => {
  const [countStart, setCountStart] = useState(false);
  const handleAnimationComplete = () => {
    // Start CountUp after animation completes
    setCountStart(true);
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const controlsstats = useAnimation();
  const { inView: inViewstats } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const controlsstats2 = useAnimation();
  const { inView: inViewstats2 } = useInView({
    /* Optional options */
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  React.useEffect(() => {
    if (inViewstats) {
      controlsstats.start("visible");
    } else {
      controlsstats.start("hidden");
    }
  }, [controlsstats, inViewstats]);

  React.useEffect(() => {
    if (inViewstats2) {
      controlsstats2.start("visible");
    } else {
      controlsstats2.start("hidden");
    }
  }, [controlsstats2, inViewstats2]);

  const fcontainer = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="stats flex flexColumn">
      <motion.div
        className="stats-title-container flex flexColumn"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={fcontainer}
      >
        <motion.h1 variants={item} className="stats-title">
          STATS
        </motion.h1>
        <motion.p variants={item} className="stats-description">
          Take a peek at our progress! Dive into a dynamic display of what we've
          achieved with real-time statistics that showcase our growth and
          impact.
          <br />
          <br />
          <span className="pink bold">
            It's not just numbers — it's our story of success and innovation!
          </span>
        </motion.p>
        <motion.div variants={item} className="black-line"></motion.div>
      </motion.div>

      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            controls
              .start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.5 },
              })
              .then(handleAnimationComplete);
          } else {
            controls.start({ opacity: 0, y: 50 });
          }
        }}
      >
        <motion.div initial={{ opacity: 0, y: 50 }} animate={controls}>
          {countStart && (
            <div className="stats-box">
              <div className="stats-container flex flexColumn">
                <CountUp className="stats-number" end={8} duration={5} />
                <p className="stats-text">CLIENTS</p>
              </div>
              <div className="stats-container flex flexColumn">
                <CountUp className="stats-number" end={2} duration={5} />
                <p className="stats-text">INFLUENCERS</p>
              </div>
              <div className="stats-container flex flexColumn">
                <CountUp className="stats-number" end={359} duration={5} />
                <p className="stats-text">SOCIAL MEDIA POSTS</p>
              </div>
              <div className="stats-container flex flexColumn">
                <CountUp className="stats-number" end={3} duration={5} />
                <p className="stats-text">WEBSITES CREATED</p>
              </div>
              <div className="stats-container flex flexColumn">
                <CountUp className="stats-number" end={36} duration={5} />
                <p className="stats-text">CAMPAIGNS LAUNCHED</p>
              </div>
              <div className="stats-container flex flexColumn">
                <CountUp className="stats-number" end={221} duration={5} />
                <p className="stats-text">CONTENT PIECES CREATED</p>
              </div>
            </div>
          )}
          {!countStart && <span>-</span>}{" "}
          {/* Show static number until animation starts */}
        </motion.div>
      </InView>
      <img className="stats-wave" src={wave} alt="" />
      <img className="footer-wave" src={wave2} alt="" />
    </div>
  );
};

export default Stats;
