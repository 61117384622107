import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// Components
import Footer from "./components/Footer.js";
import Navbar from "./components/Navbar.js";
import Landing from "./components/Landing.js";
import Offers from "./components/Offers.js";
import AboutUs from "./components/About.js";
import Stats from "./components/Stats.js";
import Privacy from "./components/Privacy.js";

// CSS
import "./App.css";

import "./css/sizes/1500px.css";
import "./css/sizes/1200px.css";
import "./css/sizes/800px.css";
import "./css/sizes/700px.css";
import "./css/sizes/600px.css";
import "./css/sizes/500px.css";
import "./css/sizes/350px.css";
import "./css/sizes/h800px.css";
import Contact from "./components/Contact.js";
import Tos from "./components/Tos.js";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  const openContact = () => {
    setIsVisible(true);
    document.body.style.overflowY = "hidden"; // Disable scrolling
  };

  const closeContact = () => {
    setIsVisible(false);
    document.body.style.overflowY = "auto"; // Enable scrolling
  };

  return (
    <Router>
      <div className="main">
        <Contact isVisible={isVisible} closeContact={closeContact} />
        <Navbar openContact={openContact} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Landing />
                <Offers />
                <AboutUs />
                <Stats />
              </>
            }
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tos" element={<Tos />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer openContact={openContact} />
      </div>
    </Router>
  );
}

export default App;
