import React, { Component } from "react";
import Marquee from "react-marquee-line";

import "../css/SocialMarquee.css";

import ig from "../visuals/IG POST.png"
import x from "../visuals/X POST.png"
import tiktok from "../visuals/TIKTOK POST.png"


export class SocialMarquee extends Component {
  render() {
    const list = [
      <img src={ig} className="marquee-img marquee-ig" alt="ECG MEDIA Instagram"></img>,
      <img src={x} className="marquee-img"  alt="ECG MEDIA X - Twitter"></img>,
      <img src={tiktok} className="marquee-img"  alt="ECG MEDIA Tiktok"></img>

    ];
    // pass the list to Marquee, and set `direction`, easy-peasy

    return (
      <div className="social-marquee flex flexColumn">
        <Marquee gear={1} lines={1} list={list} direction="horizonital" />
      </div>
    );
  }
}

export default SocialMarquee;
