import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "../css/Offers.css";

import wave from "../visuals/wave.png";
import wave4 from "../visuals/wave4.png";

import facebook from "../visuals/facebook-logo.png";
import x from "../visuals/x-logo.png";
import instagram from "../visuals/instagram-logo.png";
import linkedin from "../visuals/linkedin-logo.png";
import shopier from "../visuals/shopier-logo.png";
import shopify from "../visuals/shopify-logo.png";

const Offers = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const controlsOffers = useAnimation();
  const { ref: refOffers, inView: inViewOffers } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  const controlsOffers2 = useAnimation();
  const { inView: inViewOffers2 } = useInView({
    /* Optional options */
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  React.useEffect(() => {
    if (inViewOffers) {
      controlsOffers.start("visible");
    } else {
      controlsOffers.start("hidden");
    }
  }, [controlsOffers, inViewOffers]);

  React.useEffect(() => {
    if (inViewOffers2) {
      controlsOffers2.start("visible");
    } else {
      controlsOffers2.start("hidden");
    }
  }, [controlsOffers2, inViewOffers2]);

  const container = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  const fcontainer = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="offers flex flexColumn">
      <img className="offers-wave-upper" src={wave4} alt="" />
      <motion.div
        className="offers-title-container flex flexColumn"
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={fcontainer}
      >
        <motion.h1 variants={item} className="offers-title">
          OUR SERVICES
        </motion.h1>
        <motion.p variants={item} className="offers-description">
          Discover our range of services and solutions designed to enhance your
          success
          <br className="br" />
          <br className="br2" /> From innovative technology to expert
          consulting, find out how we can support your goals!
        </motion.p>
        <motion.div variants={item} className="black-line"></motion.div>
      </motion.div>

      <motion.div
        ref={refOffers}
        initial="hidden"
        animate={controlsOffers}
        variants={container}
        className="offers-images flex flexRow"
      >
        <motion.div variants={item}>
          <motion.img className="offers-inner-img" src={facebook} alt="" />
        </motion.div>
        <motion.div variants={item}>
          <motion.img className="offers-inner-img" src={linkedin} alt="" />
        </motion.div>
        <motion.div variants={item}>
          <motion.img className="offers-inner-img" src={instagram} alt="" />
        </motion.div>
        <motion.div variants={item}>
          <motion.img className="offers-inner-img" src={x} alt="" />
        </motion.div>
        <motion.div variants={item}>
          <motion.img className="offers-inner-img" src={shopier} alt="" />
        </motion.div>
        <motion.div variants={item}>
          <motion.img className="offers-inner-img" src={shopify} alt="" />
        </motion.div>
      </motion.div>

      <motion.div
        className="offers-inner"
        ref={refOffers}
        initial="hidden"
        animate={controlsOffers}
        variants={container}
      >
        <motion.div variants={item} className="offer-child flex flexColumn">
          <h5 className="child-title">Social Media Management</h5>
          <p className="child-description">
            Elevate your brand with our expert social media management. Boost
            engagement, grow your audience, and drive results seamlessly.
          </p>
        </motion.div>
        <motion.div variants={item} className="offer-child flex flexColumn">
          <h5 className="child-title">Social Media Marketing</h5>
          <p className="child-description">
            Transform your online presence with our dynamic social media
            marketing. Engage your audience and drive impactful results.
          </p>
        </motion.div>
        <motion.div
          variants={item}
          className="offer-child offer-child-last flex flexColumn"
        >
          <h5 className="child-title">Content Creation</h5>
          <p className="child-description">
            Engage and inspire your audience with our tailored content creation.
            Captivating visuals and compelling copy that drive results.
          </p>
        </motion.div>
        <motion.div variants={item} className="offer-child flex flexColumn">
          <h5 className="child-title">Advertisement</h5>
          <p className="child-description">
            Maximize your reach with our targeted advertising solutions. Drive
            engagement and boost your brand’s visibility effectively.
          </p>
        </motion.div>
        <motion.div variants={item} className="offer-child flex flexColumn">
          <h5 className="child-title">Website Management</h5>
          <p className="child-description">
            Optimize your online presence with our website management services.
            Ensure seamless performance, security, and user experience.
          </p>
        </motion.div>
        <motion.div
          variants={item}
          className="offer-child offer-child-last flex flexColumn"
        >
          <h5 className="child-title">Web Development</h5>
          <p className="child-description">
            Create stunning, high-performance websites with our expert web
            development services. Enhance user experience and drive growth.
          </p>
        </motion.div>
      </motion.div>
      <img className="offers-wave" src={wave} alt="" />
    </div>
  );
};

export default Offers;
