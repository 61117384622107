import React, { Component } from "react";
import { motion } from "framer-motion";

import "../css/landing.css";
import Marque from "./Marque.js";

import blob1 from "../visuals/blob1.png";
import blob2 from "../visuals/blob2.png";
import blob3 from "../visuals/blob3.png";
import AnimatedText from "./AnimatedText.js";

class CursorFollower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cursorPosition: { x: 0, y: 0 },
    };
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  componentDidMount() {
    window.addEventListener("mousemove", this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.handleMouseMove);
  }

  handleMouseMove(event) {
    this.setState({ cursorPosition: { x: event.clientX, y: event.clientY } });
  }

  calculateStyle(index) {
    const factor = 0.05; // Adjust this factor to change the movement intensity
    const { x, y } = this.state.cursorPosition;
    return {
      transform: `translate(${x * factor * (index + 1)}px, ${
        y * factor * (index + 1)
      }px)`,
    };
  }

  render() {
    return (
      <div>
        <img
          className="blob blob1"
          style={this.calculateStyle(0)}
          src={blob1}
          alt=""
        />
        <img
          className="blob blob2"
          style={this.calculateStyle(1)}
          src={blob2}
          alt=""
        />
        <img
          className="blob blob3"
          style={this.calculateStyle(2)}
          src={blob3}
          alt=""
        />
      </div>
    );
  }
}

export class Landing extends Component {
  render() {
    // ANIMATION
    const titleVariant = {
      hidden: { y: -100, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 1.5, // Duration of the animation for each child
          ease: "easeInOut",
        },
      },
    };

    const sloganVariant = {
      hidden: { y: 100, opacity: 0 },
      visible: {
        y: 0,
        opacity: 0.5,
        transition: {
          duration: 1.5, // Duration of the animation for each child
          ease: "easeInOut",
        },
      },
    };

    const tileLeftVariant = {
      hidden: { x: -100, opacity: 0 },
      visible: {
        x: 0,
        opacity: 0.5,
        transition: {
          duration: 1.5, // Duration of the animation for each child
          ease: "easeInOut",
        },
      },
    };

    const tileRightVariant = {
      hidden: { x: 100, opacity: 0 },
      visible: {
        x: 0,
        opacity: 0.5,
        transition: {
          duration: 1.5, // Duration of the animation for each child
          ease: "easeInOut",
        },
      },
    };


    return (
      <motion.div
        initial="hidden"
        animate="visible"
        id="home"
        className="landing flex flexColumn"
      >
        <CursorFollower />
        <div className="inner-landing flex flexColumn">
          <div className="landing-title flex flexRow">
            <motion.div
              variants={tileLeftVariant}
              className="white-line"
            ></motion.div>
            <motion.div variants={titleVariant} className="title">
              <span className="w600">ECG</span>{" "}
              <span className="w400">MEDIA</span>
            </motion.div>
            <motion.div
              variants={tileRightVariant}
              className="white-line"
            ></motion.div>
          </div>
          <div variants={sloganVariant} className="slogan">
            <AnimatedText text="ART OF ATTRACTION" />
          </div>
        </div>
        <Marque />
      </motion.div>
    );
  }
}

export default Landing;
