import React from "react";

import "../css/privacy.css";

const Tos = () => {
  return (
    <div className="outer-privacy-box">
      <div className="privacy-box">
        <h1 className="b-title">Terms and Conditions</h1>
        <h3 className="b-subtitle">
         Last Updated: <span className="b-date">Fri, 07 Jun 2024</span>
        </h3>
        <p className="b-desc">
          <span className="b-highlight">Welcome to ECG Media!</span>
          <br />
          These terms and conditions outline the rules and regulations for the
          use of ECG Media's Website, located at www.ecgmedia.org.
          <br />
          <br />
          <br />
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use ECG Media if you do not agree to
          take all of the terms and conditions stated on this page.
          <br />
          <br />
          <br />
          <span className="b-highlight">Definitions</span>
          <br />
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements: "Client",
          "You" and "Your" refers to you, the person log on this website and
          compliant to the Company’s terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves.
          <br />
          <br />
          <br />
          <span className="b-highlight">License</span>
          <br />
          Unless otherwise stated, ECG Media and/or its licensors own the
          intellectual property rights for all material on ECG Media. All
          intellectual property rights are reserved. You may access this from
          ECG Media for your own personal use subjected to restrictions set in
          these terms and conditions.
          <br />
          <br />
          <br />
          <span className="b-highlight">User Obligations</span>
          <br />
          You must not:
          <br />
          <div className="b-list flex flexColumn">
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                Republish material from ECG Media
              </div>
            </div>
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                Sell, rent or sub-license material from ECG Media
              </div>
            </div>
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                Reproduce, duplicate or copy material from ECG Media
              </div>
            </div>
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                Redistribute content from ECG Media
              </div>
            </div>
          </div>
          <br />
          <br />
          <span className="b-highlight">Services</span>
          <br />
          ECG Media provides various digital marketing and advertising services.
          Specific terms related to each service will be detailed in your
          service agreement or contract, which are to be agreed upon before the
          initiation of any services.
          <br />
          <br />
          <br />
          <span className="b-highlight">Payment</span>
          <br />
          ECG Media operates on a payment-for-service model. Details regarding
          payment terms, including due dates, late fees, and acceptable payment
          methods, will be specified in your service contract.
          <br />
          <br />
          <br />
          <span className="b-highlight">Cancellation and Refund</span>
          <br />
          Clients may cancel services according to the terms specified in their
          individual service agreements. ECG Media reserves the right to charge
          a cancellation fee as outlined in the service agreement.
          <br />
          <br />
          <br />
          <span className="b-highlight">Limitation of Liability</span>
          <br />
          In no event shall ECG Media, nor any of its officers, directors and
          employees, be held liable for anything arising out of or in any way
          connected with your use of this website whether such liability is
          under contract. ECG Media, including its officers, directors and
          employees shall not be held liable for any indirect, consequential or
          special liability arising out of or in any way related to your use of
          this website.
          <br />
          <br />
          <br />
          <span className="b-highlight">Indemnification</span>
          <br />
          You hereby indemnify to the fullest extent ECG Media from and against
          any and/or all liabilities, costs, demands, causes of action, damages
          and expenses arising in any way related to your breach of any of the
          provisions of these terms.
          <br />
          <br />
          <br />
          <span className="b-highlight">Severability</span>
          <br />
          If any provision of these terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
          <br />
          <br />
          <br />
          <span className="b-highlight">Variation of Terms</span>
          <br />
          ECG Media is permitted to revise these terms at any time as it sees
          fit, and by using this website you are expected to review these terms
          on a regular basis.
          <br />
          <br />
          <br />
          <span className="b-highlight">Assignment</span>
          <br />
          The Company is allowed to assign, transfer, and subcontract its rights
          and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these terms.
          <br />
          <br />
          <br />
          <span className="b-highlight">Entire Agreement</span>
          <br />
          These Terms constitute the entire agreement between ECG Media and you
          in relation to your use of this website, and supersede all prior
          agreements and understandings.
          <br />
          <br />
          <br />
          <span className="b-highlight">Governing Law & Jurisdiction</span>
          <br />
          These terms will be governed by and interpreted in accordance with the
          laws of the jurisdiction in which ECG Media is located, and you submit
          to the non-exclusive jurisdiction of the state and federal courts
          located in that jurisdiction for the resolution of any disputes.
        </p>
      </div>
    </div>
  );
};

export default Tos;
