import { motion, useTransform, useScroll, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import "../css/AboutUs.css";

import X from "../visuals/mdi_x.png";
import Linkedin from "../visuals/mdi_linkedin.png";
import Instagram from "../visuals/mdi_instagram.png";

import SocialMarquee from "./SocialMarquee";
import ContactForm from "./ContactForm";

const AboutUs = () => {
  return (
    <div className="aboutus">
      <HorizontalScrollCarousel />
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-85%"]);

  return (
    <section ref={targetRef} className="carousel-cont">
      <div className="carousel-inner">
        <div className="aboutus-slogan">
          WHERE CREATIVITY MEETS PROFESSIONALISM
        </div>
        <div className="aboutus-title">
          ABOUT<div className="gap"></div>US
        </div>
        <motion.div style={{ x }} className="carousel-card">
          {cards.map((card, index) => {
            return <Card card={card} key={index} index={index} />;
          })}
        </motion.div>
      </div>
    </section>
  );
};

const Card = ({ card, index }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const container = {
    hidden: { x: 50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={container}
      key="1"
      className={"card-container flex flexColumn card-" + (index + 1)}
    >
      <div className="card-inner"></div>
      <div className="card-inner-2 flex flexColumn">
        {card.title}
        {card.description}
      </div>
    </motion.div>
  );
};

export default AboutUs;

const cards = [
  {
    title: <p className="card-title">WHO WE ARE</p>,
    description: (
      <p className="card-description">
        At <span className="ecg">ECG</span> <span className="media">MEDIA</span>
        , we're your digital partners, specializing in social media marketing,
        advertisement across different platforms, and expert website creation,
        renovation and management.
        <br />
        <br />
        With a focus on engaging content and strategic campaigns, we elevate
        your brand's online presence, offering customizable solutions tailored
        to your unique needs. From managing social media profiles to crafting
        sleek websites, we're here to help your business thrive in the digital
        landscape.
      </p>
    ),
  },
  {
    title: <p className="card-title">HOW WE WORK</p>,
    description: (
      <div className="card-flex-container flex flexColumn">
        <div className="card-flex-upper flex flexRow">
          <div className="card-flex-inner flex flexColumn">
            <p className="card-inner-title">CONSULTATION</p>
            <p className="card-description">
              We start by understanding your business goals and target audience
              to tailor our approach
            </p>
          </div>
          <div className="card-flex-inner flex flexColumn">
            <p className="card-inner-title">STRATEGY DEVELOPMENT</p>
            <p className="card-description">
              Leveraging creativity and analytics, we craft a strategy that
              optimizes your digital presence across various platforms
            </p>
          </div>
          <div className="card-flex-inner flex flexColumn">
            <p className="card-inner-title">EXECUTION & LAUNCH</p>
            <p className="card-description">
              Our team designs and implements engaging campaigns and functional
              websites, focusing on user experience and brand consistency
            </p>
          </div>
        </div>
        <div className="card-flex-upper flex flexRow">
          <div className="card-flex-inner flex flexColumn">
            <p className="card-inner-title">MANAGEMENT & OPTIMIZATION</p>
            <p className="card-description">
              Post-launch, we continuously manage and refine your digital assets
              to ensure peak performance and relevancy
            </p>
          </div>
          <div className="card-flex-inner flex flexColumn">
            <p className="card-inner-title">REPORTING</p>
            <p className="card-description">
              We provide transparent, data-driven reports to measure success and
              guide future strategies
            </p>
          </div>
          <div className="card-flex-inner flex flexColumn last-card-desc">
            <p className="card-description">
              We start by understanding your business goals and target audience
              to tailor our approach
            </p>
          </div>
        </div>
      </div>
    ),
  },
  {
    title: <p className="card-title">SOCIAL MEDIA</p>,
    description: (
      <div className="aboutus-socialmedia flex flexRow">
        <div className="aboutus-sm-inner flex flexColumn">
          <div className="sm-inner-management flex flexColumn">
            <p className="card-inner-title">MANAGEMENT</p>
            <p className="card-description">
              Social media management creates content and engages audiences to
              boost brand visibility and foster community. Through strategic
              content, brands drive interactions on social platforms.
            </p>
          </div>
          <div className="sm-inner-advertising flex flexColumn">
            <p className="card-inner-title">ADVERTISING</p>
            <p className="card-description">
              Social media advertising targets audiences to promote products,
              boosting brand engagement and driving conversions effectively.
            </p>
          </div>
          <div className="sm-inner-follow flex flexRow">
            <p className="sm-followus">FOLLOW US</p>
            <div className="sm-followus-links flex flexRow">
              <a
                href="https://www.linkedin.com/company/ecgmedia/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="about-socials-icon" src={Linkedin} alt="" />
              </a>
              <a
                href="https://www.instagram.com/ecgmediaorg/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="about-socials-icon" src={Instagram} alt="" />
              </a>
              <a
                href="https://x.com/MediaECG"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="about-socials-icon" src={X} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="aboutus-sm-image">
          <SocialMarquee />
        </div>
      </div>
    ),
  },
  {
    title: <p className="card-title">CONTENT CREATION</p>,
    description: (
      <p className="card-description">
        At <span className="ecg">ECG</span> <span className="media">MEDIA</span>
        , content creation isn't just about filling space—it's about crafting
        compelling stories that capture hearts and minds. Our team combines
        creativity with strategic insight to produce content that resonates with
        your audience.
        <br />
        <br />
        From stunning visuals to captivating copy, we ensure every piece of
        content is purpose-driven, engaging, and perfectly aligned with your
        brand's voice.
        <br />
        <br />
        <span className="pink bold">
          Elevate your brand with content that speaks volumes!
        </span>
      </p>
    ),
  },
  {
    title: <p className="card-title">WEB DEVELOPMENT</p>,
    description: (
      <div className="aboutus-sm-inner flex flexColumn">
        <div className="sm-inner-management flex flexColumn">
          <p className="card-inner-title">MANAGEMENT</p>
          <p className="card-description">
            Keep your digital space running smoothly with{" "}
            <span className="ecg">ECG</span>{" "}
            <span className="media">MEDIA</span> 's website management services.
            We're here to ensure your site is always fresh, fast, and
            functional. From regular updates and security checks to performance
            optimization, we handle it all.
          </p>
        </div>
        <div className="sm-inner-advertising flex flexColumn">
          <p className="card-inner-title">CREATION & RENOVATION</p>
          <p className="card-description">
            Transform your online presence with <span className="ecg">ECG</span>{" "}
            <span className="media">MEDIA</span> 's website creation and
            renovation services. Whether you're building from scratch or
            revamping an existing site, our team of experts brings your vision
            to life with cutting-edge design and technology.
          </p>
        </div>
      </div>
    ),
  },
  {
    title: <p className="card-title">WE ARE ALL EARS!</p>,
    description: <ContactForm />,
  },
];
