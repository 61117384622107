import React, { Component } from "react";
import Marquee from "react-marquee-line";

import "../css/Marque.css";

export class Marque extends Component {
  render() {
    const list = [
      <span className="marquee-text">WEBSITE DEVELOPMENT</span>,
      <span className="marquee-text">WEBSITE MANAGEMENT</span>,
      <span className="marquee-text">SOCIAL MEDIA MANAGEMENT</span>,
      <span className="marquee-text">CONTENT CREATION</span>,
      <span className="marquee-text">SOCIAL MEDIA MARKETING</span>,
      <span className="marquee-text">FACEBOOK INSTAGRAM GOOGLE ADS</span>,
    ];
    // pass the list to Marquee, and set `direction`, easy-peasy

    return (
      <div className="marque flex flexRow">
        <Marquee gear={1.5} list={list} direction="horizonital" />
      </div>
    );
  }
}

export default Marque;
