import React from "react";

import "../css/privacy.css";

const Privacy = () => {
  return (
    <div className="outer-privacy-box">
      <div className="privacy-box">
        <h1 className="b-title">Privacy Policy</h1>
        <h3 className="b-subtitle">
          Last Updated: <span className="b-date">Fri, 07 Jun 2024</span>
        </h3>
        <p className="b-desc">
          <span className="b-highlight">Purpose of This Privacy Policy</span>
          <br />
          ECG Media respects your privacy and is committed to protecting your
          personal data. This privacy policy informs you about how we look after
          your personal data when you visit our website (www.ecgmedia.org) and
          tells you about your privacy rights and how the law protects you.
          <br />
          <br />
          <span className="b-highlight">
            Important Information and Who We Are
          </span>
          <br />
          This privacy policy aims to give you information on how ECG Media
          collects and processes your personal data through your use of this
          website, including any data you may provide when you sign up for our
          newsletter, purchase a service, or participate in a competition.
          <br />
          <br />
          <br />
          This website is not intended for children, and we do not knowingly
          collect data relating to children.
          <br />
          <br />
          <br />
          <span className="b-highlight">Contact Details</span>
          <br />
          For any questions or concerns regarding this privacy policy or our
          privacy practices, please contact us:
          <br />
          <br />
          <span className="b-highlight">Full name of legal entity:</span> ECG
          Media
          <br />
          <span className="b-highlight">Email address:</span>{" "}
          support@ecgmedia.org
          <br />
          <br />
          <br />
          <br />
          <span className="b-highlight">
            We Collect the Following Types of Information
          </span>
          <br />
          <div className="b-list flex flexColumn">
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">
                  Information you provide to us directly:
                </span>{" "}
                When you register for an account, we collect your full name,
                email address, and password.
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">Technical information:</span> Use
                of ECG Media’s website may result in the collection of technical
                information such as your IP address, browser type and version,
                time zone setting and location, browser plug-in types and
                versions, operating system, and platform.
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">
                  Information from third parties:
                </span>{" "}
                We may receive information about you from other sources, such as
                social media platforms, that helps us update, expand, and
                analyze our records, and provide products and services that may
                be of interest to you.
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <span className="b-highlight">How We Use Your Information</span>
          <br />
          <div className="b-list flex flexColumn">
            We use information that we receive to:
            <div className="b-list flex flexColumn">
              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Provide, improve, and personalize our services
                </div>
              </div>

              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Communicate with you about your account or transactions with
                  us and send you information about features on our site
                </div>
              </div>

              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Communicate with you about changes to our policies
                </div>
              </div>

              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Personalize content and experiences on our site and within our
                  communications
                </div>
              </div>

              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Provide you with advertising based on your activity on our
                  sites and applications and on third-party sites and
                  applications
                </div>
              </div>

              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Optimize or improve our products, services, and operations
                </div>
              </div>

              <div className="flex flexRow">
                <div className="b-dot">&#x2022;</div>
                <div className="b-dot-text">
                  Detect, investigate, and prevent activities that may violate
                  our policies or be illegal
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <span className="b-highlight">Purpose/Activity Table:</span>
          <br />
          Purpose/ActivityType of DataLawful Basis for Processing
          <div className="b-list flex flexColumn">
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                To register you as a new clientIdentity, ContactPerformance of a
                contract with you
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                To deliver services like SEO, digital advertisingTransaction,
                Marketing, CommunicationsNecessary for our legitimate interests
                (to deliver services contracted for)
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                To manage our relationship with you, including notifications of
                changes to terms or policiesIdentity, ProfileNecessary to comply
                with a legal obligation
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                To enable participation in promotions or surveysMarketing,
                CommunicationsConsent
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                To administer and protect our business and website, including
                troubleshooting and data analysisTechnical, UsageNecessary for
                our legitimate interests (for running our business, provision of
                administration and IT services, network security, to prevent
                fraud)
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                To deliver relevant website content and advertisements to you
                and measure the effectiveness of the advertising we
                serveIdentity, Contact, Profile, Usage, Marketing,
                TechnicalNecessary for our legitimate interests (to study how
                customers use our products/services, to develop them, to grow
                our business and to inform our marketing strategy)
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <span className="b-highlight">Marketing</span>
          <br />
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.
          <div className="b-list flex flexColumn">
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">Promotional offers from us:</span>{" "}
                We may use your Identity, Contact, Technical, Usage, and Profile
                Data to form a view on what we think you may want or need, or
                what may be of interest to you. This is how we decide which
                products, services, and offers may be relevant for you (we call
                this marketing).
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">Third-party marketing:</span> We
                will get your express opt-in consent before we share your
                personal data with any third party for marketing purposes.
              </div>
            </div>

            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">Opting out:</span> You can ask us
                or third parties to stop sending you marketing messages at any
                time by following the opt-out links on any marketing message
                sent to you or by contacting us at any time.
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <span className="b-highlight">Cookies</span>
          <br />
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly.
          <br />
          <br />
          <br />
          <span className="b-highlight">Change of Purpose</span>
          <br />
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you need an explanation as to how the processing for the
          new purpose is compatible with the original purpose, please contact
          us.
          <br />
          <br />
          <br />
          <span className="b-highlight">Sharing of Your Information</span>
          <br />
          We will not rent or sell your information to third parties outside of
          ECG Media without your consent, except as noted in this policy. We may
          share your information with potential business partners, service
          providers, and other entities solely to improve and provide our
          service under confidentiality terms.
          <br />
          <br />
          <br />
          <span className="b-highlight">How We Store Your Information</span>
          <div className="b-list flex flexColumn">
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">Storage and Processing:</span> We
                use commercially reasonable precautions to help keep your
                personal information safe and stored securely. However, we
                cannot ensure the security of any information you transmit to us
                or guarantee that information on our service may not be
                accessed, disclosed, altered, or destroyed.
              </div>
            </div>
            <div className="flex flexRow">
              <div className="b-dot">&#x2022;</div>
              <div className="b-dot-text">
                <span className="b-highlight">Children's Privacy:</span> ECG
                Media does not knowingly collect or solicit any information from
                anyone under the age of 13 or knowingly allow such persons to
                register for the service. If we learn that we have collected
                personal information from a child under age 13 without parental
                consent, we will delete that information as quickly as possible.
                If you believe that we might have any information from or about
                a child under 13, please contact us.
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <span className="b-highlight">
            Your Data Protection Rights Under the General Data Protection
            Regulation (GDPR)
          </span>
          <br />
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. ECG Media aims to take reasonable
          steps to allow you to correct, amend, delete, or limit the use of your
          personal data. If you wish to be informed about what personal data we
          hold about you and if you want it to be removed from our systems,
          please contact us.
          <br />
          <br />
          <br />
          <span className="b-highlight">Changes to Our Privacy Policy</span>
          <br />
          ECG Media may modify or update this Privacy Policy from time to time,
          so please review it periodically. We may provide you additional forms
          of notice of modifications or updates as appropriate under the
          circumstances. Your continued use of the website or the service after
          any modification to this Privacy Policy will constitute your
          acceptance of such modification.
          <br />
          <br />
          <br />
          <span className="b-highlight">
            For further details or any questions, you may reach us through the
            contact information provided above.
          </span>
        </p>
      </div>
    </div>
  );
};

export default Privacy;
