import React, { useState } from "react";
import "../css/Contact.css";

const Contact = ({ isVisible, closeContact }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    influencer: false,
    details: "",
    // Add other fields as necessary
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      const response = await fetch(
        `https://api.ecgmedia.org/api/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Email sent successfully!");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the email.");
    }
  };

  return (
    <div className={`contact-container ${isVisible ? "visible" : ""}`}>
      <div className="contact-content flex flexColumn">
        <p>Contact us!</p>

        <form onSubmit={handleSubmit}>
          <div id="contact" className="contact-form flex flexColumn">
            <div className="contact-checkbox-inf flex flexRow">
              <input
                type="checkbox"
                name="influencer"
                id="influencer"
                className="contact-checkbox"
                onChange={handleChange}
                checked={formData.influencer}
              />
              <p className="influencer-text pink">Are you an influencer?</p>
            </div>

            <div className="contact-form-inner flex flexColumn">
              <div className="contact-form-first flex flexColumn">
                <p className="c-form-label c-first">
                  {formData.influencer ? "YOUR NAME" : "COMPANY NAME"}{" "}
                  <span className="form-required">*</span>
                </p>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  value={formData.name}
                  onChange={handleChange}
                  maxLength={30}
                  placeholder={
                    formData.influencer ? " e.g. John Doe" : " e.g. ECG Media"
                  }
                />

                <p className="c-form-label">
                  CONTACT EMAIL <span className="form-required">*</span>
                </p>
                <input
                  type="email"
                  name="email"
                  className="form-input"
                  value={formData.email}
                  onChange={handleChange}
                  maxLength={40}
                  placeholder=" e.g. contact@ecgmedia.org"
                />
              </div>
              <div className="contact-form-second flex flexColumn">
                <p className="c-form-label">
                  TELL US WHAT YOU NEED <span className="form-required">*</span>
                </p>
                <textarea
                  type="text"
                  name="details"
                  className="form-input form-textarea c-textarea"
                  maxLength={350}
                  value={formData.details}
                  onChange={handleChange}
                  placeholder=" Detailed information about what you're looking for. Spare no details!"
                />
              </div>
            </div>
            <button className="form-submit" type="submit">
              SEND
            </button>
          </div>
        </form>

        <button className="contact-button" onClick={closeContact}>
          X
        </button>
      </div>
    </div>
  );
};

export default Contact;
