import React from "react";

import "../css/navbar.css";
import logo from "../visuals/logosmall.png";

const Navbar = ({ openContact }) => {
  return (
    <div id="navbar" className="navbar flex flexRow">
      <a href="/" className="home-link">
        <img src={logo} alt="ECG Media - Your Digital Partner" />
      </a>
      <div className="navbar-links flex flexRow">
        <a
          onClick={openContact}
          style={{ cursor: "pointer" }}
          className="contact"
        >
          CONTACT US
        </a>
        <div className="navbar-socials flex flexRow"></div>
      </div>
    </div>
  );
};

export default Navbar;
